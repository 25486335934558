// Copyright 2016 by Prahlad Yeri
// Copyright 2020 by André Kreienbring
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License version 3 as published by
// the Free Software Foundation.
// 
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
// 
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <http://www.gnu.org/licenses/>.

import {Logger} from "./js-log.js";
import {utils} from "./utils.js";

const logger = Logger.getClassLogger("codeGenerator");


/**
*	Used to generate the code for various DB or ORM Systems.
*	Every specific codegenerator is organized in it's on class.
* 	This class holds methods which are common support utilities for all of them.	
*/
class CodeGenerator {
	 constructor() {
		this.codeGenerators = {
			"ORM/SQLAlchemy": new ORMSQLAlchemy(),
			"MySQL": new MySQL(),
			"PostgreSQL": new PostgreSQL(),
			"SQLite": new SQLite(),
			"Liferay Service Builder": new LiferayServiceBuilder()
		};
	 };	
	 
	 /**
	 * After a generator was selected, finally generates the code, inserts
	 * it into the corresponding template and shows the result window.
	 * @param outputType the selected code generator
	 * @param additionalOptions may contain values set by the user in the code generator popup
	 * 		that was created by showCodeGeneratorDialog 
	 */
	 generateCode(outputType, additionalOptions) {

		// Pick code generator based on desired output format
		const selectedCodeGenerator = this.codeGenerators[outputType];
		selectedCodeGenerator.options = additionalOptions;
		
		let code = selectedCodeGenerator.generateCode();
		if (code == null){
			//don't show the resulting code because the codeGenerator indicates an error.
			return;
		} else {
			// Combine template with generated code then show the output
			jQuery.get(dbdesigner.context + "assets/templates/" + selectedCodeGenerator.template, (data) => {
				code = data.format({body: code, version: dbdesigner.version});
				this.showResults(code);
			});
		};
	 };
	 
	 /**
	 * showCodeGeneratorDialog is called when the user wants to generate code. 
	 * In the popup the code generator is selected and code generation is started.
	 * @see dbdesigner.showCodeGeneratorDialog()
	 */
	 showCodeGeneratorDialog(){
		if (Object.keys(dbdesigner.tables).length==0) {
			utils.bspopup("There should be at least one table");
			return;
		};
		
		//create the HTML object that will be evaluated by utils.bspopup. Most important part 
		//is the call back function that is triggered on change. If triggered it calls
		//the handleOptionsRequest method of the corresponding generator.
		const htmlObj = {};
		htmlObj.elements = [{
			result:"outputType", 
			type:"select", 
			text:"Select output format", 
			list:Object.keys(this.codeGenerators),
			onChange: function(event){
				logger.debug("HTML Request for " + this.value + " received");
		    	const $form = jQuery(this).closest("form")
		    	
				//clear the existing additional form fields and let the corresponding code generator handle it.
		    	$form.find("#additionalOptions").remove();
				codeGenerator.codeGenerators[this.value].handleOptionsRequest($form);
			}
		}];
		
		//open the popup with the select element that lets the user select a generator
		//also a function is passed that is called if the user clicks the passed button.
		//if the OK button is clicked the option values, that were injected by a generator, are
		//available in the passed data element.
		utils.bspopup({
			title:"Code Generator",
			type:"form",
			htmlObj: htmlObj, 
			button1: {text: "Ok", type: "btn-primary"},
			success: (data) => {
				const outputType = data.outputType.value;
				let additionalOptions = {};
				if(data.additionalOptions) additionalOptions = data.additionalOptions;
				this.generateCode(outputType, additionalOptions);
			}
		});
	 };
	 
	/**
	 * Shows a modal window with the code that was generated by a codeGenerator
	 * @param code the code that was generated
	 */
	showResults(code) {
		if (jQuery(dbdesigner.namespaceWrapper + "#resultsDialog").length==0) {
			logger.log('resultsDialog not found in cache');
			jQuery(dbdesigner.namespaceWrapper + "#holderResults").load(dbdesigner.context + "assets/partials/resultsDialog.html?time=" + (new Date()).getTime(), (data) =>{
				logger.log("resultsDialog loaded.");
				
				jQuery(dbdesigner.namespaceWrapper + "#resultsDialog").on('shown.bs.modal', function(e) {
					logger.log("ResultsDialog is shown");
				});
				
				this.showResults(code);
			});
			return;
		};
		
		jQuery(dbdesigner.namespaceWrapper + "#resultsDialog #theCode").empty();
		jQuery(dbdesigner.namespaceWrapper + "#resultsDialog #theCode").append('<pre class="prettyprint"></pre>');
		jQuery(dbdesigner.namespaceWrapper + "#resultsDialog #theCode pre").text(code);
		jQuery(dbdesigner.namespaceWrapper + "#resultsDialog").modal();
	};
		
	 
}; //class CodeGenerator

/**
 * LiferayServiceBuilder is a code generator that creates the service.xml code.
 * This xml can be used by Liferay developers to create service interfaces and an object relational mapping. 
 */
class LiferayServiceBuilder{
    constructor() {
    	this.template = "service.tpl";
    	this.rawTypes = {"Text":"String", "Integer":"long", "Float":"float", "Date":"Date", "DateTime":"Date", Serial: "long"};
    	
    	//create generator specific options, that will be set CodeGenerator.generateCode()
    	//these are also used as defaults
    	this.options = {datasource:"indi-smart", datasourcetype:"external", cache:"true", specifics:"false", remoteservice:"false"};
    	
    	//Liferay uses the following fieldnames internally. So these are forbidden if the option "specifics" is true.
    	this.reservedFieldNames = [
    		"groupId", 
    		"companyId", 
    		"userId", 
    		"userName", 
    		"createDate", 
    		"modifiedDate", 
    		"status", 
    		"statusByUserId", 
    		"statusByUserName", 
    		"statusDate"
    	];
     }
    
	/**
	 * Implement this method, if there are additional options that the code generator needs to generate the code
	 * This is a required interface method as it will be called by Codegenerator.showCodeGeneratorDialog()
	 * Mark the elements with the class "additionalOption" to have there options returned to the generator.
	 * @param $form The (currently) visible popup where input elements for the options need to be inserted.
	 * @see CodeGenerator.showCodeGeneratorDialog()
	 */
    handleOptionsRequest($form){
    	const optionsHTML = `<div id="additionalOptions">
    						<hr class="separator">
    						<p class="messageText">Please pass the following options:</p>
    						<input id="datasource" class="additionalOption form-control form-control-sm" type="text" placeholder="Data Source Name" value="${this.options.datasource}" maxlength="20"></input>
    						<select id="datasourcetype" class="additionalOption form-control form-control-sm">
    							<option value="external" ${this.options.datasourcetype == "external" ? "selected" : "''"}>External</option>"
    							<option value="internal" ${this.options.datasourcetype == "internal" ? "selected" : "''"}>Internal</option>"
    						</select>
    						<select id="cache" class="additionalOption form-control form-control-sm">
    							<option value="true" ${this.options.cache == "true" ? "selected" : "''"}>Cache enabled</option>"
    							<option value="false" ${this.options.cache == "false" ? "selected" : "''"}>Cache disabled</option>"
    						</select>
    						<select id="specifics" class="additionalOption form-control form-control-sm">
    							<option value="true" ${this.options.specifics == "true" ? "selected" : "''"}>Add Liferay specific fields</option>"
    							<option value="false" ${this.options.specifics == "false" ? "selected" : "''"}>Don't add Liferay specific fields</option>"
    						</select>
    						<select id="remoteservice" class="additionalOption form-control form-control-sm">
    							<option value="true" ${this.options.remoteservice == "true" ? "selected" : "''"}>Create Remote services</option>"
    							<option value="false" ${this.options.remoteservice == "false" ? "selected" : "''"}>Don't create Remote services</option>"
    						</select>
    						</div>
    						`
    	$form.append(optionsHTML);
    };
    
    /**
   	 * Creates a finder element for a parent table.
   	 * Liferay Service Builder then generates a finder method like for example 'findByManufacturer(manufacturerId)'
   	 * which will return all devices by one manufacturer.
   	 */
    generatePrimaryFinder(parentTableName, childFieldName){
    	let finder = `<finder name='${parentTableName}' return-type='Collection'>\n\t\t\t<finder-column name='${childFieldName}'/>\n\t\t</finder>`
     	return finder;
    };

    /**
   	 * Creates a finder element for a unique key.
   	 * Liferay Service Builder then generates a finder method like for example 'findByEAN(EAN)'
   	 * which will return the device with the EAN if it exists.
   	 */
    generateUniqueFinder(tableName, fieldName){
    	//make the first letter of the field name uppercase.
    	let finderName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    	let finder = `<finder name='${finderName}' return-type='${tableName}'>\n\t\t\t<finder-column name='${fieldName}'/>\n\t\t</finder>`
     	return finder;
    };

    /**
   	 * Creates a finder element for composite unique key.
   	 * Liferay Service Builder then generates a finder method like for example 'findByName_Id(name, id)'
   	 * which will return the device with the name AND the Id if it exists.
   	 */
    generateUniqueFinders(uniqueFinders){
     	let finderName = "";
     	let columns = "";
     	let tableName = uniqueFinders[0].tableName;
     	
     	for (let i = 0; i < uniqueFinders.length; i++){
     		let fieldName = uniqueFinders[i].fieldName;
     		
     		if (finderName != "") finderName += "_";
     		finderName += fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
     		
 			columns += `\n\t\t\t<finder-column name='${fieldName}'/>`;
		};
		
		return `\t\t<finder name='${finderName}' return-type='${tableName}'>${columns}\n\t\t</finder>\n`
    };
    
	/**
	 * Creates the XML of the service.xml
	 * This is a required interface method as it will be called by Codegenerator.generateCode()
	 * @see CodeGenerator.generateCode()
	 * @return The created XML or null (indicates an error)
	 */
	generateCode(){
		logger.debug("Options are " + JSON.stringify(this.options));
		
		let code = "";
		let noPrimaryKeyProblem = true;
		let foundReservedFieldName = false;
		
		let specificFields = "";
		if (this.options.specifics == "true"){
			specificFields += `\t\t<!-- Group Instance -->\n`;
			specificFields += `\t\t<column name='groupId' type='long'${this.options.datasourcetype == "external" ? " db-name='groupId'" : "''"}/>\n`;
			specificFields += `\t\t<column name='companyId' type='long'${this.options.datasourcetype == "external" ? " db-name='companyId'" : "''"}/>\n`;
			specificFields += `\t\t<!-- Audit fields -->\n`;
			specificFields += `\t\t<column name='userId' type='long'${this.options.datasourcetype == "external" ? " db-name='userId'" : "''"}/>\n`;
			specificFields += `\t\t<column name='userName' type='String'${this.options.datasourcetype == "external" ? " db-name='userName'" : "''"}/>\n`;
			specificFields += `\t\t<column name='createDate' type='Date'${this.options.datasourcetype == "external" ? " db-name='createDate'" : "''"}/>\n`;
			specificFields += `\t\t<column name='modifiedDate' type='Date'${this.options.datasourcetype == "external" ? " db-name='modifiedDate'" : "''"}/>\n`;
			specificFields += `\t\t<!-- Status fields -->\n`;
			specificFields += `\t\t<column name='status' type='int'${this.options.datasourcetype == "external" ? " db-name='status'" : "''"}/>\n`;
			specificFields += `\t\t<column name='statusByUserId' type='long'${this.options.datasourcetype == "external" ? " db-name='statusByUserId'" : "''"}/>\n`;
			specificFields += `\t\t<column name='statusByUserName' type='String'${this.options.datasourcetype == "external" ? " db-name='statusByUserName'" : "''"}/>\n`;
			specificFields += `\t\t<column name='statusDate' type='Date'${this.options.datasourcetype == "external" ? " db-name='statusDate'" : "''"}/>\n`;
		    specificFields += `\t\t<finder name='GroupId' return-type='Collection'>\n`;
		    specificFields += `\t\t\t<finder-column name="groupId"/>\n`;
		    specificFields += `\t\t</finder>\n`;
		}; 
		
		jQuery.each(dbdesigner.tables, (tableId, table) => {
			if (foundReservedFieldName) return;
			let tableHasPrimaryKey = false;
			let externalTable = this.options.datasourcetype == "external" ? ` table='${table.name}'` : "";
			
			code += `\t<entity data-source='${this.options.datasource}' name='${table.name}' local-service='true' cache-enabled='${this.options.cache}' remote-service='${this.options.remoteservice}'${externalTable}>\n`;
			
			let countPrimaryKeys = 0;
			let parentTableName;
			let childFieldName;
			let primaryFinders = [];
			let uniqueFinders = [];
			let uniqueCompositeFinders = [];
			
			jQuery.each(table.fields, (fieldId, field) => {
				if (this.reservedFieldNames.indexOf(field.name) != -1){
					let message = `Liferay uses the fieldname '${field.name}' in the table '${table.name}' internally.\n
						The fieldname should be changed.\n
						Reserved names are: `;
					for(let i = 0; i < this.reservedFieldNames.length; i++) message += "\n" + this.reservedFieldNames[i];
					
					if (this.options.specifics == "true"){
						foundReservedFieldName = true;
						utils.bsalert({title:"Fieldname conflict", text:message, type:"dark", delay: 0});
						return false;
					} else {
						utils.bsalert({title:"Fieldname conflict",text:message, type:"warning", delay: 0});
					}
				}
				
				if(field.primaryKey){
					countPrimaryKeys += 1;
					noPrimaryKeyProblem = noPrimaryKeyProblem && this.rawTypes[field.type] == "long";
				};
				
				let externalColumn = this.options.datasourcetype == "external" ? ` db-name='${field.name}'` : "";
				let idType = field.type == "Serial" ? " id-type='increment'" : "";
				code += `\t\t<column name='${field.name}' type='${this.rawTypes[field.type]}'${idType}${externalColumn}${field.primaryKey ? ' primary=\'true\'' : ''}/>\n`;
				
				if (field.pkRef != null){
					//In Liferay all finders need to be declared AFTER the columns
					parentTableName = dbdesigner.tables[field.pkRef.split(".")[0]].name;
					childFieldName = field.name;
					primaryFinders.push({parentTableName: parentTableName, childFieldName: childFieldName});
				};
				
				if (field.unique){
					uniqueFinders.push({tableName: table.name, fieldName: field.name});
				};
				
				if (field.uniqueComposite){
					uniqueCompositeFinders.push({tableName: table.name, fieldName: field.name});
				};
			}); //each field
			
			if (this.options.specifics == "true"){
				code += specificFields;
			};
			
			for (let i = 0; i < primaryFinders.length; i++){
				code += "\t\t" + this.generatePrimaryFinder(primaryFinders[i].parentTableName, primaryFinders[i].childFieldName) + "\n";
			};
			
			for (let i = 0; i < uniqueFinders.length; i++){
				code += "\t\t" + this.generateUniqueFinder(uniqueFinders[i].tableName, uniqueFinders[i].fieldName) + "\n";
			};
			
			if (uniqueCompositeFinders.length > 0 ) code += this.generateUniqueFinders(uniqueCompositeFinders);
			
			code += "\t</entity>\n";
			
			noPrimaryKeyProblem = noPrimaryKeyProblem && countPrimaryKeys == 1;
			
			if (foundReservedFieldName) return false;
		}); //each table
		
		if(!noPrimaryKeyProblem){
			 const message = `Liferay requires that all entities have at least one primary key.\n
			 				If there are more then one or if the type is not 'Integer' then other features, like 
			 				for example Indexing / Searching, will not work.\n
			 				You should check your tables for this rules!
			 				`
			 utils.bsalert({title:"Primary Key Problems", text:message, type:"warning", delay: 0});
		};
		
		if(foundReservedFieldName){
			return null;
		} else {
			return code;
		};
	}
};

/**
 * ORMSQLAlchemy is an Python code Generator 
 */
class ORMSQLAlchemy{
    constructor() {
    	this.template = "sqlalchemy.py";
     	this.options = {};
     }
    
	/**
	 * Implement this method, if there are additional options that the code generator needs to generate the code
	 * This is a required interface method as it will be called by Codegenerator.showCodeGeneratorDialog()
	 * Mark the elements with the class "additionalOption" to have there options returned to the generator.
	 * @param $form The (currently) visible popup where input elements for the options need to be inserted.
	 * @see CodeGenerator.showCodeGeneratorDialog()
	 */
    handleOptionsRequest($form){
    	//noop
    };
    
	/**
	 * Creates Python code 
	 * This is a required interface method as it will be called by Codegenerator.generateCode()
	 * @see CodeGenerator.generateCode()
	 * @return The created Python code.
	 */
	generateCode(){
		let code = '';
		
		 jQuery.each(dbdesigner.tables, function(tableId, table) {
			code += "class " + table.name + "(Base):\n";
			code += "\t" + "__tablename__ = \"" + table.name + "\"\n";
			jQuery.each(table.fields, function(fieldId, field){
				//embed quotes if they don't already exist
				if (field.type=='Text' || field.type=='String') {
					if (field.defaultValue!=null) {
						var sdef = field.defaultValue;
						if (sdef.indexOf('"') !=0) field.defaultValue = '"' + sdef;
						if (sdef.lastIndexOf('"') != sdef.length-1 || sdef.lastIndexOf('"')==-1) field.defaultValue += '"';
					};
					// Default text size is 255 if user didn't specify a size
					if (field.size==0) {
						field.size = 255;
					};
				};
				
				code += "\t" + field.name + " = Column(" 
				+ field.type + (field.size==0 ? '' : '(' + field.size + ')')
				+ (field.pkRef != null ? ", ForeignKey('" + field.pkRef + "')" : "")
				+ (field.primaryKey ? ", primary_key=True" : "")
				+ (field.unique ? ", unique=True" : "")
				+ (field.notNull ? ", nullable=False" : "")
				+ (field.defaultValue!=null ? ", default=" + field.defaultValue : "")
				+ ")\n";
			});
			code += "\n";
		});

		return code;
	}
};

/**
 * MySQL is an internal SQL Generator. The generated SQL work also with PostgresSQL and probably MariaDB 
 */
class MySQL{
    constructor() {
    	this.template = "mysql.sql";
    	this.rawTypes = {"Text": "varchar", "Integer": "int","Float": "float", "Date": "date", "DateTime": "datetime", Serial: "int AUTO_INCREMENT"};
    	this.compositeUnique = "unique key";
    	this.deferForeignKeys = true; // Add foreign key constraint after running CREATE TABLE statement?
    	this.options = {};
   		this.options = {generateLiferayFields: "false"};
    }
    
	/**
	 * Implement this method, if there are additional options that the code generator needs to generate the code
	 * This is a required interface method as it will be called by Codegenerator.showCodeGeneratorDialog()
	 * Mark the elements with the class "additionalOption" to have there options returned to the generator.
	 * @param $form The (currently) visible popup where input elements for the options need to be inserted.
	 * @see CodeGenerator.showCodeGeneratorDialog()
	 */
    handleOptionsRequest($form){
    	const optionsHTML = `<div id="additionalOptions">
    						<hr class="separator">
    						<p class="messageText">Please pass the following options:</p>
    						<select id="generateLiferayFields" class="additionalOption form-control form-control-sm">
    							<option value="true">Generate Liferay specific fields</option>"
    							<option value="false" selected>Don't generate Liferay specific fields</option>"
    						</select>
    						</div>
    						`
    	$form.append(optionsHTML);
    };
    
	/**
	 * Creates a primary / foreign key relation between two tables
	 * @param sourceTableName The table that is referenced by a primary key from another table
	 * @param sourceTableFields The fields which are referenced by a primary key fields in another table
	 * @param targetTableName The table that has the primary keys which are referencing the source table
	 * @param targetTableFields The primary field(s) in the target table that are referencing the source table
	 * @param deleteCascade If true child record are deleted if a parent record is deleted
	 * @return The alter table statement to create the primary / foreign key relation.
	 '
	 */
    generateFKConstraint (sourceTableName, sourceTableFields, targetTableName, targetTableFields, deleteCascade) {
    	return `alter table ${sourceTableName} add constraint fk_${sourceTableName}_${targetTableName} foreign key (${sourceTableFields}) references ${targetTableName} (${targetTableFields})${deleteCascade ? " ON DELETE CASCADE;" : ";"}`
    };
    
	/**
	 * Creates the SQL for MySQL
	 * This is a required interface method as it will be called by Codegenerator.generateCode()
	 * @see CodeGenerator.generateCode()
	 * @return The created SQL.
	 */
    generateCode(){
		let code = '';
		let constraints = [];
		
		let specificFields = "";
		if (this.options.generateLiferayFields == "true"){
			specificFields += `\t-- Liferay Group Instance\n`;
			specificFields += `\tgroupId int,\n`;
			specificFields += `\tcompanyId int,\n`;
			specificFields += `\t-- Liferay Audit fields\n`;
			specificFields += `\tuserId int,\n`;
			specificFields += `\tuserName varchar(255),\n`;
			specificFields += `\tcreateDate datetime,\n`;
			specificFields += `\tmodifiedDate datetime,\n`;
			specificFields += `\t-- Liferay Status fields\n`;
			specificFields += `\tstatus int,\n`;
			specificFields += `\tstatusByUserId int,\n`;
			specificFields += `\tstatusByUserName varchar(255),\n`;
			specificFields += `\tstatusDate datetime`;
		}; 

		jQuery.each(dbdesigner.tables, (tableId, table) => {
			logger.info("Generating Code for Table " + table.name);
			
			code += "create table " + table.name + "\n(\n";
			
			const primaryFields = [];
			const uniqueCompositeFields = [];
			let primaryCount = 0;
			let uniqueCompositeCount = 0;
			let targetTable;
			let targetField;
			
			// Collect number and names of primary and uniqueComposite keys
			// This extra loop is needed because in the next loop the counts are used
			jQuery.each(table.fields, function(fieldId, field) {
				if (field.primaryKey) {
					primaryFields.push(field.name);
					primaryCount += 1;
				};
				if (field.uniqueComposite) {
					uniqueCompositeFields.push(field.name);
					uniqueCompositeCount += 1;
				};
			});
			
			let fieldCode = [];
			let referencedTables = [];
			
			//generate the table and field definitions
			jQuery.each(table.fields, (fieldId, field) =>
			{
				if (field.type=='Text' || field.type=='String') {
					//embed quotes if they don't already exist
					if (field.defaultValue!=null) {
						const sdef = field.defaultValue;
						if (sdef.indexOf('"') !=0) field.defaultValue = '"' + sdef;
						if (sdef.lastIndexOf('"') != sdef.length-1 || sdef.lastIndexOf('"')==-1) field.defaultValue += '"';
					}
					
					// Default text size is 255 if user didn't specify a size
					if (field.size==0) {
						field.size = 255;
					}
				}
				
				fieldCode.push("\t" + field.name + " " + this.rawTypes[field.type] + (field.size==0 ? '' : '(' + field.size + ')')
					+ (field.notNull ? " not null" : "")
					+ (field.primaryKey && primaryCount == 1 ? " primary key" : "")
					+ (field.unique || (field.uniqueComposite && uniqueCompositeCount == 1) ? " unique" : "")
					+ (field.defaultValue != null ? " default " + field.defaultValue  : "")
				);
				
				if (field.pkRef != null) 
				{
					targetTable = dbdesigner.tables[field.pkRef.split(".")[0]];
					targetField = targetTable.fields[field.pkRef.split(".")[1]];
					
					//push to generate (composite) references later
					//this is an array of arrays. Each entry holds the table / field names and if the foreign field supports a delete cascade
					logger.debug("Pushing [" + targetTable.name+ "." + targetField.name + "," + table.name + "." + field.name +"," + field.deleteCascade + "] to referencedTables");
					referencedTables.push([targetTable.name+ "." + targetField.name, table.name + "." + field.name, field.deleteCascade]);
				};
				
			}); //for each field
			
			if (this.options.generateLiferayFields == "true"){
				fieldCode.push(specificFields);
			};
				
			// Add multi-field primary key if needed
			if (primaryCount > 1) {
				fieldCode.push("\tprimary key (" + primaryFields.join(', ') + ")");
			};
			
			if (uniqueCompositeCount > 1) {
				fieldCode.push("\t"+ this.compositeUnique + " (" + uniqueCompositeFields.join(', ') + ")");
			};
			
			//now generate the constraints with respect to composite keys
			let targetTableName;
			let targetFieldName;
			let sourceTableName;
			let sourceFieldName;
			let deleteCascade;
			let sourceTableFields = "";
			let targetTableFields = "";
			let lastReferencedTable = "";
			let constraint = "";
			
			//sort the array to have references to the same table in sequence
			if(referencedTables.length > 0){
				referencedTables.sort(([a], [b]) => a < b ? -1 : a > b ? 1 : 0);
				logger.debug("sorted referencedTables = " + JSON.stringify(referencedTables));
				
				targetTableName = referencedTables[0][0].split(".")[0];
				targetFieldName = referencedTables[0][0].split(".")[1];
				sourceTableName = referencedTables[0][1].split(".")[0];
				sourceFieldName = referencedTables[0][1].split(".")[1];
				deleteCascade = referencedTables[0][2];
				
				targetTableFields = targetFieldName;
				sourceTableFields = sourceFieldName;
				
				lastReferencedTable = targetTableName;
			}; 
			
			for (let i=1; i < referencedTables.length; i++){
				
				if (referencedTables[i][0].split(".")[0] == lastReferencedTable && referencedTables.length > 1){
					//keep on going until the table name changes
				}else{
					if (targetTableFields == "") targetTableFields = targetFieldName;
					if (sourceTableFields == "") sourceTableFields = sourceFieldName;
					
					// add any constraints placed by raw formats like MySQL and PostgreSQL.
					// save constraints in an array (they are added after all tables have been created)
					constraint = this.generateFKConstraint(sourceTableName, sourceTableFields, targetTableName, targetTableFields, deleteCascade);
					
					logger.debug("Adding constraint: " + constraint);
					constraints.push(constraint);
					targetTableFields = "";
					sourceTableFields = "";
				};
				
				targetTableName = referencedTables[i][0].split(".")[0];
				targetFieldName = referencedTables[i][0].split(".")[1];
				sourceTableName = referencedTables[i][1].split(".")[0];
				sourceFieldName = referencedTables[i][1].split(".")[1];
				//if one of the field supports delete cascade it overrules the others.
				deleteCascade = deleteCascade || referencedTables[i][2];
				
				if (targetTableFields != ""){
					targetTableFields += ", " + targetFieldName;
					sourceTableFields += ", " + sourceFieldName;
				}else{
					targetTableFields = targetFieldName;
					sourceTableFields = sourceFieldName;
				};
				
				lastReferencedTable = targetTableName;
			};
			
			//add the remaining constraints
			if (targetTableFields != ""){
				// add any constraints placed by raw formats like MySQL and PostgreSQL.
				// save constraints in an array (they are added after all tables have been created)
				constraint = this.generateFKConstraint(sourceTableName, sourceTableFields, targetTableName, targetTableFields, deleteCascade);
				logger.debug("Adding constraint: " + constraint);
				constraints.push(constraint);
			};
				
			// Add foreign key lines now if needed
			if (!this.deferForeignKeys) {
				fieldCode = fieldCode.concat(constraints);
				constraints = [];
			}
			
			// Add all the lines for declaring fields, primary keys, and FKs (if needed)
			code += fieldCode.join(",\n")+"\n);\n";
			
		}); //for each table

		// If foreign keys have to come after everything else, add them here
		if (this.deferForeignKeys) {
			code += constraints.join("\n");
		};
	
		return code;
    }
};

/**
 * PostgreSQL is an internal code Generator.
 * PostgreSQL inherits from MySQL. It's mostly the same syntax, the only difference is that
 * autoincrement and composite unique are noted in a different way
  */
class PostgreSQL extends MySQL{
	constructor(){
		super();
		this.rawTypes = {"Text": "varchar", "Integer": "int","Float": "float", "Date": "date", "DateTime": "datetime", Serial: "SERIAL"};
		this.compositeUnique = "unique";
	}
}
	
/**
 * SQLite is an internal code Generator.
 * SQLite inherits from MySQL. It's mostly the same syntax, the only difference is that
 * MySQL doesn't support ALTER TABLE ADD CONSTRAINT FOREIGN KEY, so FKs have to be added
 * as part of the CREATE TABLE statement. 
 */
class SQLite extends MySQL{
	constructor(){
		
		super();
		this.template = "sqlite.sql";
		
		// Add foreign key constraint after running CREATE TABLE statement?
		this.deferForeignKeys = false;
		this.options = {};
	}
	
	/**
	 * Implement this method, if there are additional options that the code generator needs to generate the code
	 * This is a required interface method as it will be called by Codegenerator.showCodeGeneratorDialog()
	 * Mark the elements with the class "additionalOption" to have there options returned to the generator.
	 * @param $form The (currently) visible popup where input elements for the options need to be inserted.
	 * @see CodeGenerator.showCodeGeneratorDialog()
	 */
    handleOptionsRequest($form){
    	//noop
    };
    
	/**
	 * Creates a primary / foreign key relation between two tables
	 * @param sourceTableName The table that is referenced by a primary key from another table
	 * @param sourceTableFields The fields which are referenced by a primary key fields in another table
	 * @param targetTableName The table that has the primary keys which are referencing the source table
	 * @param targetTableFields The primary field(s) in the target table that are referencing the source table
	 * @return The alter table statement to create the primary / foreign key relation.
	 '
	 */
    generateFKConstraint (sourceTableName, sourceTableFields, targetTableName, targetTableFields) {
		return "\tforeign key (" + sourceTableFields +  ") references " + targetTableName +  "(" + targetTableFields  + ")"
	}
}; 

const codeGenerator = new CodeGenerator();
export {codeGenerator};